<template>
    <transition name="fade">

        <div v-show="show">
            <mdb-card class="mb-2  animated fadeIn"
                      style="margin-top:-1rem">
                <mdb-card-body class="p-2">
                    <mdb-btn tag="a"
                             size="sm"
                             outline="primary"
                             @click="$router.push('/product/purchase/list')">
                        <i class="fa fa-search"></i>
                        進貨單查詢
                    </mdb-btn>
                </mdb-card-body>
            </mdb-card>
            <mdb-card class="mb-2">
                <mdb-card-body class="pt-0">
                    <mdb-row>
                        <mdb-col md="3">
                            <mdb-input label="進貨時間"
                                       :value="purchase.day"
                                       disabled></mdb-input>
                        </mdb-col>
                        <mdb-col md="3">
                            <mdb-input label="分店"
                                       :value="storeKeyList[purchase.id_store]?storeKeyList[purchase.id_store].name:''"
                                       disabled></mdb-input>
                        </mdb-col>
                        <mdb-col md="3">
                            <mdb-input label="進貨人"
                                       :value="adminKeyList[purchase.id_admin]?adminKeyList[purchase.id_admin].name:''"
                                       disabled></mdb-input>
                        </mdb-col>
                    </mdb-row>
                </mdb-card-body>
            </mdb-card>
            <mdb-card class="mb-2">
                <mdb-card-body>
                    <mdb-row>
                        <mdb-col md="4">

                        </mdb-col>
                    </mdb-row>
                    <div class="">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th style="width:5rem">#</th>
                                    <th>商品名稱</th>
                                    <th style="width:10rem">數量</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(p,pk) in purchase.item_list"
                                    :key="`p_${pk}`">
                                    <td>{{pk+1}}</td>
                                    <td>
                                        <small>{{productKeyList[p.id_product] ? productKeyList[p.id_product].code : ''}}</small><br>
                                        {{productKeyList[p.id_product] ? productKeyList[p.id_product].name : ''}}
                                    </td>
                                    <td>
                                        {{p.num}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </transition>
</template>

<script>
import { mdbCard, mdbCardBody, mdbRow, mdbCol, mdbInput, mdbBtn } from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      show: false,
      purchase: { itemList: [] },
      storeKeyList: [],
      productKeyList: [],
      adminKeyList: {},
    };
  },
  mounted() {
    this.get_data();
  },
  updated() {},
  methods: {
    get_data() {
      let vue = this,
        query = [
          {
            name: "product",
            data: {},
          },
          {
            name: "admin",
            data: {},
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
          // 取出空的進貨單
          {
            name: "purchase",
            data: { id: { type: 0, value: vue.$route.params.id_purchase } },
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          // res.data.purchaseList[0].itemList = JSON.parse(
          //   res.data.purchaseList[0].itemList
          // );
          vue.purchase = res.data.purchaseList[0];
          vue.purchase.item_list = JSON.parse(vue.purchase.item_list);
          res.data.adminList.forEach((item) => {
            vue.adminKeyList[item.id] = item;
          });
          res.data.storeList.forEach((item) => {
            vue.storeKeyList[item.id] = item;
          });
          vue.productKeyList = [];
          res.data.productList.forEach((item) => {
            vue.productKeyList[item.id] = {
              name: item.name,
              code: item.code,
            };
          });
          vue.show = true;
          // console.info(res.data);
        });
    },
  },
};
</script>